import React, { useEffect, useState } from 'react';
import { Stack, Button, Select, MenuItem, FormControl, TextField, Autocomplete, Snackbar, Paper, Modal, Box, Typography, FormControlLabel, Checkbox, ListItemText } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: '10%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                    name: props.name,
                    value: values.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
        />
    );
});
const EditTradeTicketDialog = ({ initialTradeData, onSave, open, handleClose, tradingPairOptions }) => {
    const [tradeData, setTradeData] = useState();
    const [spotRate, setSpotRate] = useState();
    const [dealtRate, setDealtRate] = useState();
    const [tradeId, setTradeId] = useState('');
    const [tradingPair, setTradingPair] = useState('');
    const [liquidityProvider, setLiquidityProvider] = useState([]);
    const [amount, setAmount] = useState(0);
    const [currency, setCurrency] = useState('');
    const [ccyDeposited, setCcyDeposited] = useState('');
    const [ccyPurchased, setCcyPurchased] = useState('');
    const [quoteCurrency, setQuoteCurrency] = useState('');
    const [execPrice, setExecPrice] = useState(0);
    const [execTimestamp, setExecTimestamp] = useState(dayjs());
    const [platform, setPlatform] = useState('');
    const [orderId, setOrderId] = useState('');
    useEffect(() => {
        console.log('EditTradeTicketDialog',initialTradeData);
        
        setTradeData({...initialTradeData})
    }, [initialTradeData])
    useEffect(() => {
        if(tradeData){
            // set liquidity provider state
            const liquidityProviders = tradeData.liquidity_provider ? tradeData.liquidity_provider.split(', '): []
            let execAmount = tradeData.amount.split(' ');
            let execPrice = tradeData.price ? isNaN(parseFloat(tradeData.price.replace(/,/g, ""))) ? 0 : parseFloat(tradeData.price.replace(/,/g, "")) : 0
            let execTimestamp = dayjs(tradeData.date)
            setDealtRate(tradeData.dealt_rate)
            setSpotRate(tradeData.spot_price)
            console.log(tradeData.asset_pair);
            setTradeId(tradeData.trade_id)
            setTradingPair(tradeData.asset_pair)
            setLiquidityProvider(liquidityProviders)
            setAmount(execAmount[0])
            setCurrency(execAmount[1])
            //setQuoteCurrency(tradeData.asset_pair)
            setExecPrice(execPrice)
            setExecTimestamp(execTimestamp)
            setPlatform(tradeData.platform)
            setOrderId(tradeData.order_id)
        }
    }, [tradeData])
    useEffect(() => {
        if(tradeData){
            let [newCurrency, newQuoteCurrency] = tradingPair.split('/'); 
            console.log(currency);
            setCurrency(newCurrency)
            let depositedValue = tradeData.ccy_deposited.split(' ')[0]
                let purchasedValue = tradeData.ccy_purchased.split(' ')[0]
            if(tradeData.type == 'Buy'){
                // ccy deposited -> quote currency 
                depositedValue = depositedValue + " " + newQuoteCurrency
                purchasedValue = purchasedValue + " " + newCurrency
            }
            else {
                // ccy deposited -> quote currency 
                depositedValue = depositedValue + " " + newCurrency
                purchasedValue = purchasedValue + " " + newQuoteCurrency
            }
            setCcyDeposited(depositedValue)
            setCcyPurchased(purchasedValue)
        }
        
    }, [tradingPair, tradeData])
    const handleSave = () => {
        onSave(tradeData);
    };
    // state change
    const handlePriceChange = (event) => {
        const {
            target: { value },
        } = event;
        setExecPrice(value)
    }
    const handleLiquidityProviderChange = (event) => {
        const {
            target: { value },
        } = event;
        let trade = {...tradeData}
        
        let liquidityProviders = typeof value === 'string' ? value.split(',') : value
        trade.liquidity_provider =liquidityProviders.join(', ')
        // setTradeData(trade)
        setLiquidityProvider(liquidityProviders);
    };
    const handleExecPlatformChange = (e) => {
        const value = e.target.value
        setPlatform(value)
    }
    const handleExecDateTimeChange = (date) => {
        // Handle the change event here
        console.log(date);
        setExecTimestamp(date)
        //setExecTimestamp(date)
    };
    const handleTradeIdChange = (e) => {
        const value = e.target.value
        setTradeId(value)
    }
    const handleSpotChange = (e) => {
        const value = e.target.value
        setSpotRate(value)
    }
    const handleDealtChange = (e) => {
        const value = e.target.value
        setDealtRate(value)
    }
    const handleSaveChanges = () => {
        let trade = {...tradeData}
        trade.liquidity_provider = liquidityProvider.join(', ')
        trade.amount = amount + ' ' + currency
        trade.trade_id = tradeId
        trade.price = execPrice
        trade.platform = platform
        trade.order_id = orderId
        trade.date = execTimestamp.toISOString()
        trade.asset_pair = tradingPair
        trade.ccy_deposited = ccyDeposited
        trade.ccy_purchased = ccyPurchased
        console.log(trade);
        
        setTradeData(trade)
        onSave(trade)
    }
    if(tradeData){
    return (
        <div>
            <Modal
                open={open && tradeData}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                <Stack sx={{mb:2}} style={{
                        width: {
                            xs: '100%', // 100% width on xs screens
                            sm: '30%',  // 30% width on sm screens and above
                        }
                    }} spacing={0}>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={1} width={'100%'} sx={{justifyContent: 'space-between'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>Trade Id</div>
                            <FormControl sx={{ m: 1, minWidth: 250 }}>
                                <TextField 
                                    value={tradeId}
                                    size='small'
                                    onChange={handleTradeIdChange}
                                />
                            </FormControl>
                        </Stack>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={1} width={'100%'} sx={{justifyContent: 'space-between'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>Spot Rate</div>
                            <FormControl sx={{ m: 1, minWidth: 250 }}>
                                <TextField 
                                    value={spotRate}
                                    size='small'
                                    onChange={handleSpotChange}
                                    InputProps={{
                                        inputComponent: NumericFormatCustom,
                                        endAdornment: tradingPair
                                    }}
                                />
                            </FormControl>
                        </Stack>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={1} width={'100%'} sx={{justifyContent: 'space-between'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>Dealt Rate</div>
                            <FormControl sx={{ m: 1, minWidth: 250 }}>
                                <TextField 
                                    value={dealtRate}
                                    size='small'
                                    onChange={handleDealtChange}
                                    InputProps={{
                                        inputComponent: NumericFormatCustom,
                                        endAdornment: tradingPair
                                    }}
                                />
                            </FormControl>
                        </Stack>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={1} width={'100%'} sx={{justifyContent: 'space-between'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>Trading Pair</div>
                            <FormControl sx={{ m: 1, minWidth: 200 }}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={tradingPairOptions}
                                    sx={{width: '100%'}}
                                    size='small'
                                    value={tradingPair}
                                    freeSolo
                                    filterOptions={(options, state) => {
                                        const inputValue = state.inputValue.toLowerCase();
                                        
                                        return options
                                            .filter((option) => option.label.toLowerCase().includes(inputValue))
                                            .sort((a, b) => {
                                                const aLabel = a.label.toLowerCase();
                                                const bLabel = b.label.toLowerCase();
                                                
                                                // Prioritize options starting with input value
                                                const aStartsWith = aLabel.startsWith(inputValue);
                                                const bStartsWith = bLabel.startsWith(inputValue);
                                                
                                                if (aStartsWith && !bStartsWith) {
                                                    return -1;
                                                }
                                                if (!aStartsWith && bStartsWith) {
                                                    return 1;
                                                }
                                                
                                                // If both or neither starts with the input, retain alphabetical order
                                                return aLabel.localeCompare(bLabel);
                                            });
                                    }}
                                    onInputChange={(e, v) => {
                                        // console.log('input', e);
                                        // console.log('input', v);
                                        setTradingPair(v.toUpperCase())}}
                                    // onChange={(e, v) => {
                                    //     console.log(v)
                                    //     setTradingPair(v ? v.value : '')
                                    // }} // example of v: {value: 'BTC/CAD', label: 'BTC/CAD'}
                                    renderInput={(params) => 
                                    <TextField {...params} placeholder="Assets Pair"/>}
                                />
                            </FormControl>
                        </Stack>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={1} width={'100%'} sx={{justifyContent: 'space-between'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>LP</div>
                            <FormControl sx={{ minWidth: 150 }}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={liquidityProvider}
                                    onChange={handleLiquidityProviderChange} required
                                    multiple
                                    displayEmpty
                                    renderValue={(selected) => selected.join(', ')}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{widht: '100%', maxWidth: '250px'}}
                                    size='small'
                                >
                                    <MenuItem value={'cross'} sx={{ height: '32px', padding: '4px 8px' }}>
                                        <Checkbox checked={liquidityProvider.indexOf('cross') > -1} />
                                        <ListItemText primary={'cross'} />
                                    </MenuItem>
                                    <MenuItem value={'aquanow'} sx={{ height: '32px', padding: '4px 8px' }}>
                                        <Checkbox checked={liquidityProvider.indexOf('aquanow') > -1} />
                                        <ListItemText primary={'aquanow'} />
                                    </MenuItem>
                                    <MenuItem value={'b2c2'} sx={{ height: '32px', padding: '4px 8px' }}>
                                        <Checkbox checked={liquidityProvider.indexOf('b2c2') > -1} />
                                        <ListItemText primary={'b2c2'} />
                                    </MenuItem>
                                    <MenuItem value={'cumberland'} sx={{ height: '32px', padding: '4px 8px' }}>
                                        <Checkbox checked={liquidityProvider.indexOf('cumberland') > -1} />
                                        <ListItemText primary={'cumberland'} />
                                    </MenuItem>
                                    <MenuItem value={'dvchain'} sx={{ height: '32px', padding: '4px 8px' }}>
                                        <Checkbox checked={liquidityProvider.indexOf('dvchain') > -1} />
                                        <ListItemText primary={'dvchain'} />
                                    </MenuItem>
                                    <MenuItem value={'enigma'} sx={{ height: '32px', padding: '4px 8px' }}>
                                        <Checkbox checked={liquidityProvider.indexOf('enigma') > -1} />
                                        <ListItemText primary={'enigma'} />
                                    </MenuItem>
                                    <MenuItem value={'flowtraders'} sx={{ height: '32px', padding: '4px 8px' }}>
                                        <Checkbox checked={liquidityProvider.indexOf('flowtraders') > -1} />
                                        <ListItemText primary={'flowtraders'} />
                                    </MenuItem>
                                    <MenuItem value={'galaxy'} sx={{ height: '32px', padding: '4px 8px' }}>
                                        <Checkbox checked={liquidityProvider.indexOf('galaxy') > -1} />
                                        <ListItemText primary={'galaxy'} />
                                    </MenuItem>
                                    <MenuItem value={'mobilum'} sx={{ height: '32px', padding: '4px 8px' }}>
                                        <Checkbox checked={liquidityProvider.indexOf('mobilum') > -1} />
                                        <ListItemText primary={'mobilum'} />
                                    </MenuItem>
                                    <MenuItem value={'nonco'} sx={{ height: '32px', padding: '4px 8px' }}>
                                        <Checkbox checked={liquidityProvider.indexOf('nonco') > -1} />
                                        <ListItemText primary={'nonco'} />
                                    </MenuItem>
                                    <MenuItem value={'wintermute'} sx={{ height: '32px', padding: '4px 8px' }}>
                                        <Checkbox checked={liquidityProvider.indexOf('wintermute') > -1} />
                                        <ListItemText primary={'wintermute'} />
                                    </MenuItem>
                                    <MenuItem value={'falconx'} sx={{ height: '32px', padding: '4px 8px' }}>
                                        <Checkbox checked={liquidityProvider.indexOf('falconx') > -1} />
                                        <ListItemText primary={'falconx'} />
                                    </MenuItem>
                                    <MenuItem value={'stillmandigital'} sx={{ height: '32px', padding: '4px 8px' }}>
                                        <Checkbox checked={liquidityProvider.indexOf('stillmandigital') > -1} />
                                        <ListItemText primary={'stillmandigital'} />
                                    </MenuItem>
                                    <MenuItem value={'crypto.com'} sx={{ height: '32px', padding: '4px 8px' }}>
                                        <Checkbox checked={liquidityProvider.indexOf('crypto.com') > -1} />
                                        <ListItemText primary={'crypto.com'} />
                                    </MenuItem>
                                    <MenuItem value={'kraken'} sx={{ height: '32px', padding: '4px 8px' }}>
                                        <Checkbox checked={liquidityProvider.indexOf('kraken') > -1} />
                                        <ListItemText primary={'kraken'} />
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={1} width={'100%'} sx={{justifyContent: 'space-between'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>Amt</div>
                            <FormControl sx={{ m: 1, minWidth: 180 }}>
                                <TextField 
                                    value={amount}
                                    disabled
                                    required
                                    size='small'
                                    InputProps={{
                                        inputComponent: NumericFormatCustom,
                                        endAdornment: currency
                                    }}
                                />
                            </FormControl>
                        </Stack>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={1} width={'100%'} sx={{justifyContent: 'space-between'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>Price</div>
                            <FormControl sx={{ m: 1, minWidth: 150 }}>
                                <TextField 
                                    value={execPrice} 
                                    onChange={handlePriceChange}
                                    InputProps={{
                                        inputComponent: NumericFormatCustom,
                                        endAdornment: tradingPair
                                    }}
                                    required
                                    size='small'
                                />
                            </FormControl>
                        </Stack>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={1} width={'100%'} sx={{justifyContent: 'space-between'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>Platform</div>
                            <FormControl sx={{ minWidth: 150 }}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={platform}
                                    onChange={handleExecPlatformChange} required
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{widht: '100%'}}
                                    size='small'
                                >
                                    <MenuItem value={'SDMLive'}>SDMLive</MenuItem>
                                    <MenuItem value={'AXE'}>AXE</MenuItem>
                                    <MenuItem value={'Telegram'}>Telegram</MenuItem>
                                    <MenuItem value={'Signal'}>Signal</MenuItem>
                                    <MenuItem value={'Whatsapp'}>Whatsapp</MenuItem>
                                    <MenuItem value={'Email'}>Email</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={1} width={'100%'} sx={{justifyContent: 'space-between'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>OrderId</div>
                            <FormControl sx={{ m: 1, minWidth: 170 }}>
                                <TextField 
                                    value={orderId} 
                                    onChange={(e) => {
                                        setOrderId(e.target.value)
                                    }}
                                    required
                                    size='small'
                                />
                            </FormControl>
                        </Stack>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={1} width={'100%'} sx={{justifyContent: 'space-between'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>Date</div>
                                <FormControl sx={{ m: 1, minWidth: 120 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker 
                                            // label="Basic date time picker" 
                                            slotProps={{ textField: { size: 'small', hiddenLabel: true } }}
                                            onChange={handleExecDateTimeChange}
                                            value={execTimestamp}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Stack>
                    </Stack>
                    <Stack direction='row' justifyContent='flex-end' spacing={3}>
                        <Button onClick={handleSaveChanges}>Save Changes</Button>
                        <Button variant='outlined' color='error' onClick={handleClose}>Cancel</Button>            
                    </Stack>
                    
                </Box>
            </Modal>
        </div>
    );
}
};

export default EditTradeTicketDialog;
